<template>
  <div class="app-sidebar-jobs" :class="{ collapsed: collapsed }">
    <div
      v-for="company in companies"
      :key="company.id"
      class="app-sidebar-jobs-group"
    >
      <button
        class="app-sidebar-jobs-company"
        :class="{ collapsed: collapsedCompanies.includes(company.id) }"
        @click="collapseCompany(company.id)"
      >
        <a-icon class="app-sidebar-jobs-company-arrow" type="down" />
        {{ company.name }}
      </button>
      <ul
        class="app-sidebar-jobs-list"
        :dir="$root.$i18n.locale === 'ar' ? 'rtl' : 'ltr'"
      >
        <template v-for="(job, index) in jobs[company.id]">
          <li
            v-if="index + 1 <= jobsLimit"
            :key="job.id"
            class="app-sidebar-jobs-item"
          >
            <router-link
              :to="`/jobs/vacancy/${job.id}`"
              :class="[
                'app-sidebar-jobs-link',
                {
                  'app-sidebar-jobs-link-disabled': index + 1 > jobsLimit,
                  'router-link-exact-active': job.id === viewedJobId
                }
              ]"
            >
              <div class="app-sidebar-jobs-name">
                {{ job.name }}
              </div>
              <a-badge
                :count="job.interviewedCount"
                show-zero
                :number-style="{
                  color: '#fff',
                  fontWeight: 600,
                  fontSize: '10px',
                  backgroundColor: 'rgba(255, 255, 255, 0.30)',
                  height: '17px',
                  lineHeight: '17px'
                }"
              ></a-badge>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppButton from './AppButton.vue';
import PageTitle from './PageTitle.vue';
import IconBurger from './icons/Burger.vue';
import IconPlus from './icons/Plus.vue';

export default {
  name: 'AppSidebarJobs',

  components: {
    AppButton,
    PageTitle,
    IconPlus,
    IconBurger
  },

  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      collapsedCompanies: []
    };
  },

  computed: {
    ...mapState({
      jobs: ({ jobs }) =>
        jobs.jobs
          .filter((job) => job.active)
          .reduce((acc, job) => {
            if (!acc[job.companyId]) {
              acc[job.companyId] = [];
            }
            acc[job.companyId].push(job);
            return acc;
          }, {}),
      jobsLimit: ({ user }) => user.plan.jobsLimit,
      viewedJobId: ({ jobs }) => jobs.viewedJobId,
      companies: ({ company }) => company.companies
    })
  },

  methods: {
    collapseCompany(companyId) {
      const index = this.collapsedCompanies.findIndex(
        (item) => item === companyId
      );

      if (index === -1) {
        this.collapsedCompanies.push(companyId);
      } else {
        this.collapsedCompanies.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="less">
.app-sidebar-jobs {
  &-group {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &-company {
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    background: none;
    padding: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    cursor: pointer;
    margin-bottom: 8px;

    &-arrow {
      flex-shrink: 0;
      font-size: 10px;
      margin-right: 8px;
      transition: all 0.15s ease-out;
    }

    &.collapsed {
      .app-sidebar-jobs-company-arrow {
        transform: rotate(-90deg);
      }

      & + .app-sidebar-jobs-list {
        display: none;
      }
    }
  }

  &-list {
    padding: 0;
    list-style-type: none;
  }

  &-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.15s ease-out;

    &:hover {
      color: #fff;
    }

    &.router-link-exact-active {
      .ant-badge-count {
        background: #ffab42 !important;
      }
    }

    &:hover,
    &.router-link-exact-active {
      background: rgba(255, 255, 255, 0.1);
    }

    .ant-badge-count {
      min-width: 28px;
      box-shadow: none;
    }
  }

  &-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
