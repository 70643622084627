export const SET_APP_LOADING = 'SET_APP_LOADING';
export const TOGGLE_UPGRADE_MODAL = 'TOGGLE_UPGRADE_MODAL';

export const SET_CONFIG = 'SET_CONFIG';
// export const SET_APP_INDUSTRIES = 'SET_APP_INDUSTRIES';
// export const SET_APP_ROLES = 'SET_APP_ROLES';
// export const SET_SUBJECTS = 'SET_SUBJECTS';
// export const SET_PERMISSIONS = 'SET_PERMISSIONS';
// export const SET_VIDEO_CHAT_SERVER_URL = 'SET_VIDEO_CHAT_SERVER_URL';
export const SET_MENU_JOBS = 'SET_MENU_JOBS';
export const SET_USERS = 'SET_USERS';

export const SET_MSG = 'SET_MSG';
export const SET_LNG = 'SET_LNG';
export const SET_BCP = 'SET_BCP';

// USER
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_PLAN = 'SET_PLAN';
export const SET_PLANS = 'SET_PLANS';
export const SET_TAXES = 'SET_TAXES';
export const SET_PLAN_API_KEY = 'SET_PLAN_API_KEY';
export const SET_PLAN_INTENT = 'SET_PLAN_INTENT';

// COMPANIES
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANY_USERS = 'SET_COMPANY_USERS';

// JOBS
export const SET_JOBS = 'SET_JOBS';
export const SET_VIEWED_JOB_ID = 'SET_VIEWED_JOB_ID';

// INTERVIEW
export const SET_INTERVIEW_INFO = 'SET_INTERVIEW_INFO';
export const SET_INTERVIEW_USER_INFO = 'SET_INTERVIEW_USER_INFO';
export const SET_INTERVIEW_ANSWERS = 'SET_INTERVIEW_ANSWERS';

export const SET_LIVE_INTERVIEWS = 'SET_LIVE_INTERVIEWS';
