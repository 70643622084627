<template>
  <div class="user-dropdown-container" @click="toggleDropdown">
    <div class="user">
      <a-avatar v-if="avatar" class="avatar-wrapper" :size="30" :src="avatar">
        <icon-user-default-avatar></icon-user-default-avatar>
      </a-avatar>
      <Initials v-else />
    </div>
    <!-- Dropdown Menu -->
    <div v-if="isOpen" class="dropdown-menu">
      <router-link to="/profile" class="dropdown-item">{{
        $t('profile_nav.account')
      }}</router-link>
      <router-link to="/profile/plan" class="dropdown-item">{{
        $t('profile_nav.plan')
      }}</router-link>
      <router-link to="/profile/usage" class="dropdown-item">{{
        $t('profile_nav.billing')
      }}</router-link>
      <router-link to="/profile/integrations" class="dropdown-item">{{
        $t('profile_nav.integrations')
      }}</router-link>

      <button @click="logout" class="dropdown-item logout mt-10">
        {{ $t('sidebar.links.log_out') }}
      </button>
    </div>
  </div>
</template>

<script>
import removeTokenFromLocalStorage from '../js/helpers/removeTokenFromLocalStorage.js';
import IconUserDefaultAvatar from './icons/UserDefaultAvatar.vue';
import Initials from './Initials.vue';

export default {
  name: 'User',

  components: {
    IconUserDefaultAvatar,
    Initials
  },

  props: {
    to: {
      type: String,
      default: '/'
    },

    avatar: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    closeDropdown(event) {
      if (!event.target.closest('.user-dropdown-container')) {
        this.isOpen = false;
      }
    },
    logout() {
      removeTokenFromLocalStorage();
      this.$router.go('/login');
    }
  },

  watch: {
    avatar(value) {
      console.log('avatar', value);
    }
  },

  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  }
};
</script>

<style lang="scss">
.user {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  //@media (max-width: $osx) {
  //  padding: 0 10px;
  //}
  //
  //@media (max-width: $lg) {
  //  padding: 0;
  //  width: 90px;
  //}
  //
  //@media (max-width: $sm) {
  //  width: 80px;
  //  height: 80px;
  //}
}

.avatar-wrapper {
  cursor: pointer;
}
.user-dropdown-container {
  position: relative;
  display: inline-block;
  z-index: 2000;

  .dropdown-menu {
    position: absolute;
    top: 42px;
    right: 0;
    width: 250px;
    padding: 30px;
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
    display: flex;
    flex-direction: column;
    visibility: visible;
    opacity: 1;
  }

  .dropdown-item {
    display: block;
    padding-bottom: 3px;
    color: black;
    text-decoration: none;
    transition: background 0.3s, color 0.3s;
    font-size: 14px;
    font-weight: 600;
    line-height: 2.2;

    &:hover {
      color: #ffab42;
    }

    &.logout {
      padding: 0;
    }
  }

  .dropdown-divider {
    margin: 5px 0;
  }

  .logout {
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
  }
}

/* Ensure all parent containers allow overflow */
.navbar,
.navbar-container,
.some-other-parent {
  overflow: visible !important;
}

/* Smooth fade animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
