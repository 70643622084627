// export const BASE_PATH = 'http://localhost:8000/';
// export const API_BASE_PATH = 'http://localhost:8000/api/';
// export const BASE_PATH_APP_URL = 'http://localhost:8080/';

export const BASE_PATH = 'https://api.hrblade.com/';
export const API_BASE_PATH = 'https://api.hrblade.com/api/';
export const BASE_PATH_APP_URL = 'https://app.hrblade.com/';

export const TOKEN_EXPIRATION_PERIOD = 365;
export const currency = ['USD', 'EUR', 'GBR', 'RUB'];
export const BASE_PATH_URL = {
  en: 'https://hrblade.com/',
  ru: 'https://ru.hrblade.com/',
  de: 'https://de.hrblade.com/'
};
