<template>
  <div
    class="initials"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
      fontSize: `${fontSize}px`
    }"
  >
    {{ initials }}
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Initials',

  props: {
    size: {
      type: Number,
      default: 30
    },
    fontSize: {
      type: Number,
      default: 12
    }
  },

  computed: {
    ...mapState({
      name: ({ user }) => user.info.name
    }),

    initials() {
      if (!this.name) {
        return '';
      }

      const fullName = this.name.trim();
      let words = fullName.split(' ');
      words = words.filter((word) => word !== '');
      let initials = words.map((word) => word.charAt(0).toUpperCase());
      return initials.slice(0, 2).join('');
    }
  }
};
</script>

<style scoped lang="scss">
.initials {
  flex-shrink: 0;
  border-radius: 50%;
  background: #363151;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
