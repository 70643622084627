var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-sidebar-jobs",class:{ collapsed: _vm.collapsed }},_vm._l((_vm.companies),function(company){return _c('div',{key:company.id,staticClass:"app-sidebar-jobs-group"},[_c('button',{staticClass:"app-sidebar-jobs-company",class:{ collapsed: _vm.collapsedCompanies.includes(company.id) },on:{"click":function($event){return _vm.collapseCompany(company.id)}}},[_c('a-icon',{staticClass:"app-sidebar-jobs-company-arrow",attrs:{"type":"down"}}),_vm._v(" "+_vm._s(company.name)+" ")],1),_c('ul',{staticClass:"app-sidebar-jobs-list",attrs:{"dir":_vm.$root.$i18n.locale === 'ar' ? 'rtl' : 'ltr'}},[_vm._l((_vm.jobs[company.id]),function(job,index){return [(index + 1 <= _vm.jobsLimit)?_c('li',{key:job.id,staticClass:"app-sidebar-jobs-item"},[_c('router-link',{class:[
              'app-sidebar-jobs-link',
              {
                'app-sidebar-jobs-link-disabled': index + 1 > _vm.jobsLimit,
                'router-link-exact-active': job.id === _vm.viewedJobId
              }
            ],attrs:{"to":("/jobs/vacancy/" + (job.id))}},[_c('div',{staticClass:"app-sidebar-jobs-name"},[_vm._v(" "+_vm._s(job.name)+" ")]),_c('a-badge',{attrs:{"count":job.interviewedCount,"show-zero":"","number-style":{
                color: '#fff',
                fontWeight: 600,
                fontSize: '10px',
                backgroundColor: 'rgba(255, 255, 255, 0.30)',
                height: '17px',
                lineHeight: '17px'
              }}})],1)],1):_vm._e()]})],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }