<template>
  <div class="default-layout">
    <app-sidebar :menu-collapsed="menuCollapsed"></app-sidebar>

    <div class="default-layout-content">
      <app-header
        :menu-collapsed="menuCollapsed"
        :mobile-menu-visible="mobileMenuVisible"
        @open-mobile-menu="mobileMenuVisible = !mobileMenuVisible"
        @toggle-menu="menuCollapsed = !menuCollapsed"
      />

      <app-mobile-navigation
        :visible="mobileMenuVisible"
        @close-mobile-menu="mobileMenuVisible = false"
      />

      <div v-if="isTarifNeedUpgrade" class="upgrade-plan-notify">
        {{ $t("You've exceeded your current plan limits.") }}

        <router-link to="/profile">
          {{ $t('Please upgrade your account.') }}
        </router-link>
      </div>

      <router-view />

      <app-footer />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AppHeader from '../components/AppHeader.vue';
import AppSidebar from '../components/AppSidebar.vue';
import AppMobileNavigation from '../components/AppMobileNavigation.vue';
import AppFooter from '../components/AppFooter.vue';

export default {
  name: 'DefaultLayout',

  components: {
    AppHeader,
    AppSidebar,
    AppMobileNavigation,
    AppFooter
  },

  data() {
    return {
      menuCollapsed: false,
      mobileMenuVisible: false
    };
  },

  computed: {
    isTarifNeedUpgrade() {
      return this.responsesCount >= this.responsesLimit;
    },

    ...mapState({
      responsesCount: ({ user }) => user.plan.responsesCount,
      responsesLimit: ({ user }) => user.plan.responsesLimit,
      usersLimit: ({ user }) => user.plan.usersLimit,
      jobsLimit: ({ user }) => user.plan.jobsLimit,
      companiesLimit: ({ user }) => user.plan.companiesLimit,

      usersCount: ({ company }) => company.users.length,
      jobsCount: ({ jobs }) => jobs.jobs.length,
      companiesCount: ({ company }) => company.companies.length
    })
  }
};
</script>

<style lang="scss">
.default-layout {
  width: 100%;
  display: flex;

  .app-sidebar {
    @media (max-width: $lg) {
      display: none;
    }
  }
}

.default-layout-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  @media (max-width: $lg) {
    padding-top: calc(59px + env(safe-area-inset-top));
  }

  .container {
    max-width: 1600px;
  }
}

.upgrade-plan-notify {
  width: 100%;
  padding: 5px;
  text-align: center;
  background-color: #dd2705;
  color: #ffffff;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}
</style>
