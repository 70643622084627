<template>
  <nav class="app-desktop-navigation">
    <ul class="app-desktop-navigation-list">
      <li
        v-for="(link, index) in links"
        :key="index"
        class="app-desktop-navigation-list-item"
      >
        <router-link
          :to="link.to"
          :class="[
            'app-desktop-navigation-list-link',
            { 'only-exact': link.to === '/' }
          ]"
        >
          <a-icon
            v-if="link.icon"
            :type="link.icon"
            class="app-desktop-navigation-list-icon mr-10"
            style="color: #b6b6b9"
          />

          <span class="app-desktop-navigation-list-link-label mr-5">
            {{ link.label }}
          </span>

          <a-badge
            class="app-desktop-navigation-list-badge"
            show-zero
            :count="link.count"
            :number-style="link.accent ? badgeAccentStyles : badgeStyles"
          />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppDesktopNavigation',

  data() {
    return {
      badgeStyles: {
        color: '#373151',
        fontWeight: 600,
        fontSize: '10px',
        backgroundColor: '#EBEBF3',
        height: '17px',
        lineHeight: '17px'
      },
      badgeAccentStyles: {
        color: '#fff',
        fontWeight: 600,
        fontSize: '10px',
        backgroundColor: '#FFAB42',
        height: '17px',
        lineHeight: '17px'
      }
    };
  },

  computed: {
    links() {
      return [
        {
          to: '/',
          count: this.jobsCount,
          icon: 'unordered-list',
          label: this.$t('navbar.links.jobs'),
          accent: true
        },
        this.liveAvailable
          ? {
              to: '/live',
              count: this.interviewsCount,
              label: this.$t('navbar.links.live')
            }
          : null,
        {
          to: '/companies',
          count: this.companiesCount,
          label: this.$t('navbar.links.companies')
        },
        {
          to: '/users',
          count: this.usersCount,
          label: this.$t('navbar.links.users')
        }
      ].filter((link) => !!link);
    },

    ...mapState({
      liveAvailable: ({ user }) => user.plan.live,
      jobsCount: ({ jobs }) => jobs.jobs.length,
      companiesCount: ({ company }) => company.companies.length,
      usersCount: ({ company }) => company.users.length,
      interviewsCount: (state) => state.live.interviews.length
    })
  }
};
</script>

<style lang="scss">
$link-color-active: #fda94c;

.app-desktop-navigation {
  height: 100%;

  @media (max-width: $xl) {
    margin: 20px;
  }

  @media (max-width: $lg) {
    display: none;
  }
}

.app-desktop-navigation-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100%;
  list-style: none;
}

.app-desktop-navigation-list-item {
  height: 100%;

  &:not(:last-of-type) {
    margin-right: 30px;

    @media (max-width: $osx) {
      margin-right: 20px;
    }
  }
}

.app-desktop-navigation-list-link {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  line-height: 1;
  font-family: 'Open Sans', sans-serif;

  &.router-link-active:not(.only-exact) {
    color: $link-color-active;
  }

  &.only-exact.router-link-exact-active {
    color: $link-color-active;
  }
}

.app-desktop-navigation-list-icon {
  path {
    fill: #373151;
  }
}

.app-desktop-navigation-list-badge {
  .ant-badge-count {
    min-width: 34px;
  }
}

.app-desktop-navigation-list-link-label {
  font-weight: 300;
}
</style>
